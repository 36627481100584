/**
 * This contains functions related to user-defined data filters (used in the filters.vue component).
 */

import { getValueFromObject } from '@/functions/utils.js';
import { customTabulatorFilters } from '@/functions/customTabulatorFilters.js';

const getVal = (record, fieldKey) => {
    const result = getValueFromObject(record, fieldKey);
    if (!Array.isArray(result)) return result;
    if (result[0]?.value === undefined) return result;
    return result[0].value;
};

// apply user-defined filters
export const applyFilters = ({data, filters}) => {
    let result = data;

    if (!filters) return result;

    filters.forEach(filter => {
        if (filter.type === '=') {
            if (filter.displayName === 'is exactly') {
                result = result.filter(record => {
                    const a = filter.value, b = getVal(record, filter.field);
                    if (typeof a !== 'string') return true;
                    if (typeof b !== 'string') return true;    
                    return a.toLowerCase() == b.toLowerCase();
                });
            } else {
                result = result.filter(record => getVal(record, filter.field) == Number(filter.value));
            }
        } else if (filter.type === 'like') {
            result = result.filter(record => {
                const a = filter.value, b = getVal(record, filter.field);
                if (typeof a !== 'string') return true;
                if (typeof b !== 'string') return true;
                return b.toLowerCase().includes(a.toLowerCase());
            });
        } else if (filter.type === '>') {
            result = result.filter(record => getVal(record, filter.field) > Number(filter.value));
        } else if (filter.type === '<') {
            result = result.filter(record => getVal(record, filter.field) < Number(filter.value));
        } else if (filter.type === '>=') {
            result = result.filter(record => getVal(record, filter.field) >= Number(filter.value));
        } else if (filter.type === '<=') {
            result = result.filter(record => getVal(record, filter.field) <= Number(filter.value));
        } else if (filter.type === 'customIn') {
            result = result.filter(record => filter.value.includes(getVal(record, filter.field)));
        } else if (filter.type === 'customEquals') {
            result = result.filter(record => customTabulatorFilters.customEquals(filter.value, getVal(record, filter.field)));
        } else if (filter.type === 'myTagFilter') {
            // just check to see if at least one myTag is present in the record. This is not exclusive filtering. We want to check for any matches.
            result = result.filter(record => filter.value.some(value => record._myTags.includes(value)));
        } else if (filter.type === 'tagsFilter') {
            result = result.filter(record => filter.value.some(val => val === 'No Tag'? record.tags.length === 0 : record.tags[0]?.value.includes(val)));
        }
    });
    return result;
};
